<!-- invoking color picker briefly just as a workaround to initialize picker size cache at app start -->


<div [ngClass]="{'ctn-root': !isHandset, 'ctn-root-mbl': isHandset}">
    <nt-navbar [animationEndEventSubject]="animationEndEventSubject"
               [productPages]="productPages"></nt-navbar>

    <nt-content-container [animationEndEventSubject]="animationEndEventSubject"
                          [productPages]="productPages"></nt-content-container>
</div>

