import {AbstractComponent} from './abstract-component';

export class ProductPage {
    pageSelected = false;
    pageElement: HTMLElement;
    pageComp: AbstractComponent;
    hidden = true;

    constructor(public path: string,
                public navTitle: string,
                public browserTitle: string = null,
                public sectionTitle: string = null,
                public mobileSectionTitle: string = null,
                public showInNavbar = true,
                private regex = null) {
        if(!this.browserTitle) {
            this.browserTitle = this.navTitle + ' - Nifty Thoughts';
        }
        if(!this.sectionTitle) {
            this.sectionTitle = this.navTitle;
        }
        if(!this.mobileSectionTitle) {
            this.mobileSectionTitle = this.navTitle;
        }
    }

    pageTransitionStart() {
        if(this.pageComp) {
            this.pageComp.pageTransitionStart();
        }
    }

    afterPageTransitionStart() {
        if(this.pageComp) {
            this.pageComp.afterPageTransitionStart();
        }
    }

    pageTransitionEnd() {
        if(this.pageComp) {
            this.pageComp.pageTransitionEnd();
        }
    }

    pageResized() {
        if(this.pageComp) {
            this.pageComp.pageResized();
        }
    }

    matchesPath(path: string) {
        if(this.regex == null)
            return this.path === path;
        return path.match(this.regex);
    }
}
