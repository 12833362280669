

<div [ngClass]="{'ctn-parent': !isHandset, 'ctn-parent-mbl': isHandset}"
    class="pg-ctn">
    <nt-ansi-highlighter *ngIf="isANSIHighlighterPage()"
                         [productPage]="productPage"
                         class="subpage"></nt-ansi-highlighter>

    <nt-ansi-highlighter-eula *ngIf="isANSIHighlighterEula()"
                              [productPage]="productPage"
                              class="subpage"></nt-ansi-highlighter-eula>

    <nt-ansi-highlighter-license-register *ngIf="isANSIHighlighterLicenseRegister()"
                                          [productPage]="productPage"
                                          class="subpage"></nt-ansi-highlighter-license-register>

    <nt-footer></nt-footer>
</div>
