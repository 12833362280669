import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {AbstractComponent} from '../../abstract-component';
import {ProductPage} from '../../product-page';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ResponsiveConstants} from '../../responsive-constants';
import {JbProductUiDescr} from './jb-product-ui-descr';

@Component({
  selector: 'nt-ansi-highlighter',
  templateUrl: './ansi-highlighter.component.html',
  styleUrls: ['./ansi-highlighter.component.scss', '../content-container.component.scss']
})
export class AnsiHighlighterComponent extends AbstractComponent implements OnInit {
    static readonly PRODUCT = new ProductPage('/ansihighlighter',
        'ANSI Highlighter',
        'ANSI Highlighter Premium',
        'ANSI Highlighter Premium',
        'ANSI Highlighter');

    public productSpriteClass: string[];
    public jbProducts: JbProductUiDescr[];
    public isViewLoaded = false;
    public bannerImgScale = 0;
    public ssImgScale = 0;

    public readonly bannerImgStaticWidth = 1240;
    public readonly bannerImgStaticHeight = 280;

    public bannerImgWidth: number;
    public bannerImgHeight: number;



    public readonly ssImgStaticWidth = 2216;
    public readonly ssImgStaticHeight = 532;


    public ssImgWidth: number;
    public ssImgHeight: number;


    public readonly smSSImghMargin = 30 * 2; // static margin (px)
    public readonly mdSSImghMargin = 60 * 2; // static margin (px)
    public readonly lgSSImghMargin = 90 * 2; // static margin (px)
    public readonly xlSSImghMargin = 0.15 * 2; // margin relative to width (%)

    private lastWindowWidth = 0;


    constructor(public breakpointObserver: BreakpointObserver) {
        super(breakpointObserver);
        AnsiHighlighterComponent.PRODUCT.pageComp = this;
    }

    ngOnInit() {
        super.ngOnInit();
        this.productSpriteClass = ['bg-intellij_prod_ij_60',
            'bg-intellij_prod_ps_60',
            'bg-intellij_prod_pc_60',
            'bg-intellij_prod_ws_60',
            'bg-intellij_prod_cl_60',
            'bg-intellij_prod_rm_60',
            'bg-intellij_prod_go_60',
            'bg-intellij_prod_rd_60',
            'bg-intellij_prod_ac_60',
            'bg-intellij_prod_dg_60'];
        this.jbProducts = [
            new JbProductUiDescr("PyCharm", "#pycharm", "https://www.jetbrains.com/pycharm/download/"),
            new JbProductUiDescr("Android Studio", "#android-studio", "https://developer.android.com/studio"),
            new JbProductUiDescr("IntelliJ IDEA Ultimate", "#intellij-idea", "https://www.jetbrains.com/idea/download/"),
            new JbProductUiDescr("IntelliJ IDEA Community ", "#intellij-idea-ce", "https://www.jetbrains.com/idea/download/"),
            new JbProductUiDescr("WebStorm", "#webstorm", "https://www.jetbrains.com/webstorm/download/"),
            new JbProductUiDescr("DataGrip", "#datagrip", "https://www.jetbrains.com/datagrip/download"),
            new JbProductUiDescr("MPS", "#mps", "https://www.jetbrains.com/mps/download/"),
            new JbProductUiDescr("RubyMine", "#rubymine", "https://www.jetbrains.com/ruby/download/"),
            new JbProductUiDescr("CLion", "#clion", "https://www.jetbrains.com/clion/download/"),
            new JbProductUiDescr("AppCode", "#appcode", "https://www.jetbrains.com/objc/download/"),
            new JbProductUiDescr("GoLand", "#goland", "https://www.jetbrains.com/go/download/"),
            new JbProductUiDescr("DataSpell", "#dataspell", "https://www.jetbrains.com/dataspell/download/"),
            new JbProductUiDescr("PhpStorm", "#phpstorm", "https://www.jetbrains.com/phpstorm/download/"),
            new JbProductUiDescr("Rider", "#rider", "https://www.jetbrains.com/rider/download/"),
            new JbProductUiDescr("PyCharm Educational", "#pycharm-edu", "https://www.jetbrains.com/edu-products/download/#section=pycharm-edu"),
            new JbProductUiDescr("IntelliJ IDEA Educational ", "#intellij-idea-edu", "https://www.jetbrains.com/edu-products/download/#section=idea"),
            new JbProductUiDescr("Code With Me Guest", "#code-with-me", "https://www.jetbrains.com/edu-products/download/#section=idea"),
            new JbProductUiDescr("JetBrains Gateway", "#gateway", "https://www.jetbrains.com/remote-development/gateway/")

        ];
        const self = this;
        this.tick_then(() => {
            self.pageResizeReflected();
            self.isViewLoaded = true;
        });
    }

    pageResizeReflected() {
        const windowWidth = window.innerWidth;

        this.bannerImgScale = windowWidth / this.bannerImgStaticWidth;
        this.bannerImgWidth = windowWidth;
        this.bannerImgHeight = Math.trunc(this.bannerImgStaticHeight * this.bannerImgScale);

        if(windowWidth <= ResponsiveConstants.SM_MAX_W) {
            this.ssImgScale = (windowWidth - this.smSSImghMargin) / this.ssImgStaticWidth;
        } else if(windowWidth <= ResponsiveConstants.MD_MAX_W) {
            this.ssImgScale = (windowWidth - this.mdSSImghMargin) / this.ssImgStaticWidth;
        } else if(windowWidth <= ResponsiveConstants.LG_MAX_W) {
            this.ssImgScale = (windowWidth - this.lgSSImghMargin) / this.ssImgStaticWidth;
        } else {
            this.ssImgScale = (windowWidth * (1 - this.xlSSImghMargin) ) / this.ssImgStaticWidth;
        }
        this.ssImgWidth = Math.trunc(this.ssImgStaticWidth * this.ssImgScale);
        this.ssImgHeight = Math.trunc(this.ssImgStaticHeight * this.ssImgScale);
    }

    isPageResizeReflected(): boolean {
        if(this.lastWindowWidth !== window.innerWidth) {
            this.lastWindowWidth = window.innerWidth;
            return true;
        }
        return false;
    }

    pageTransitionStart() {
        this.pageResized();
    }

    pageTransitionEnd() {
        super.pageTransitionEnd();
    }
}
