import { Component, OnInit } from '@angular/core';
import {ProductPage} from '../../../product-page';
import {AbstractComponent} from '../../../abstract-component';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
  selector: 'nt-ansi-highlighter-eula',
  templateUrl: './ansi-highlighter-eula.component.html',
  styleUrls: ['./ansi-highlighter-eula.component.scss', '../../content-container.component.scss']
})
export class AnsiHighlighterEulaComponent extends AbstractComponent implements OnInit {
    static readonly PRODUCT = new ProductPage('/eula',
        'EULA',// navTitle doesn't apply
        'ANSI Highlighter Premium "EULA"',
        'ANSI Highlighter Premium "EULA"',
        'EULA');

  constructor(public breakpointObserver: BreakpointObserver) {
      super(breakpointObserver);
      AnsiHighlighterEulaComponent.PRODUCT.pageComp = this;
  }

  ngOnInit(): void {
      super.ngOnInit();
  }

}
