<nav *ngIf="!isHandset" class="navbar">
    <div class="nav-shd footer"></div>
    <div class="nav-container {{responsiveCls}}">
        <div class="nt-logo-wrp">
            <a href="https://niftythoughts.com" target="_blank" rel="noopener noreferrer" class="bg-logo_text_64 sprite"></a>
        </div>
        <div class="copyright-wrp">
            <span class="copyright">Copyright © {{year}} - Nifty Thoughts OÜ</span>
        </div>
    </div>
</nav>

<nav *ngIf="isHandset" class="navbar-mbl">
    <div class="nav-shd-mbl footer"></div>
    <div class="nav-container {{responsiveCls}}">
        <div class="nt-logo-wrp">
            <a href="https://niftythoughts.com" target="_blank" rel="noopener noreferrer" class="bg-logo_text_64 sprite"></a>
        </div>
        <div class="copyright-wrp">
            <span class="copyright">Copyright © {{year}}</span>
        </div>
    </div>
</nav>
