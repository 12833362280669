<!-- using opacity because display none and or visibility hidden have side effects-->
<div style="opacity: 0; position: absolute">
    <svg version="2.0" height="40" width="40">
        <defs>
            <clipPath id="leaf-clip">
                <circle cx="24%" cy="32.50%" r="40.62%"/>
            </clipPath>
            <circle id="leaf" cx="76%" cy="32.50%" r="40.62%" clip-path="url(#leaf-clip)"/>

            <clipPath id="double-clip">
                <use href="#leaf" transform="rotate(45, 20, 20)" />
                <use href="#leaf" transform="rotate(-45, 20, 20)" />
            </clipPath>

            <use id="leaf-tip" href="#leaf" clip-path="url(#double-clip)"/>

            <symbol id="ah-logo" height="40" width="40">
                <use href="#leaf" fill="#8ECCEB" transform="rotate(22.5, 20, 20)" />
                <use href="#leaf" fill="#8ECCEB" transform="rotate(112.5, 20, 20)" />
                <use href="#leaf" fill="#8ECCEB" transform="rotate(157.5, 20, 20)" />
                <use href="#leaf" fill="#8ECCEB" transform="rotate(202.5, 20, 20)" />
                <use href="#leaf" fill="#8ECCEB" transform="rotate(247.5, 20, 20)" />
                <use href="#leaf" fill="#8ECCEB" transform="rotate(337.5, 20, 20)" />

                <use href="#leaf-tip" fill="#54B2E0" transform="rotate(22.5, 20, 20)" />
                <use href="#leaf-tip" fill="#54B2E0" transform="rotate(112.5, 20, 20)" />
                <use href="#leaf-tip" fill="#54B2E0" transform="rotate(157.5, 20, 20)" />
                <use href="#leaf-tip" fill="#54B2E0" transform="rotate(202.5, 20, 20)" />
                <use href="#leaf-tip" fill="#54B2E0" transform="rotate(247.5, 20, 20)" />
                <use href="#leaf-tip" fill="#54B2E0" transform="rotate(337.5, 20, 20)" />

                <use href="#leaf" fill="#1498D6" transform="rotate(67.5, 20, 20)" />
                <use href="#leaf" fill="#1498D6" transform="rotate(292.5, 20, 20)" />

                <path d="M432,32H80A64.07,64.07,0,0,0,16,96V416a64.07,64.07,0,0,0,64,64H432a64.07,64.07,0,0,0,64-64V96A64.07,64.07,0,0,0,432,32Z"
                      transform="translate(18, 18) scale(0.5, 0.5) scale(0.0741, 0.0781) translate(10, 0)"/>

                <path d="M96,256a16,16,0,0,1-10-28.49L150.39,176,86,124.49a16,16,0,1,1,20-25l80,64a16,16,0,0,1,0,25l-80,64A16,16,0,0,1,96,256Zm160,0H192a16,16,0,0,1,0-32h64a16,16,0,0,1,0,32Z"
                      fill="white"
                      transform="translate(18, 18) scale(0.5, 0.5) scale(0.0741, 0.0781) translate(10, 0)"/>
            </symbol>
        </defs>
    </svg>
</div>

<nav *ngIf="!isHandset" class="navbar">
    <div class="nav-container {{responsiveCls}}">
        <div class="nav-items">
            <ul >
                <li *ngFor="let productPage of mainProductPages" [ngClass]="{'active': productPage.pageSelected}">
                    <a href="#{{productPage.path}}" (click)="onNavItemClick($event, productPage)">{{productPage.navTitle}}</a>
                </li>
            </ul>
        </div>
        <div class="logo-wrp">
            <svg version="2.0">
                <use href="#ah-logo" height="40" width="40"/>
            </svg>
        </div>
    </div>
    <div class="nav-shd"></div>
</nav>

<nav *ngIf="isHandset" class="navbar-mbl">
    <div class="nav-container {{responsiveCls}}">
        <div class="menu-btn-dv">
            <svg (click)="onResponsiveBtnClick()">
                <path d="M0 0h36v36H0z" fill="none"></path>
                <circle *ngIf="showMenuItemsInHandsetMode" cx="18" cy="18" r="18" fill="#DDDDDD"></circle>
                <path d="M10 12h16v2H10zM10 17h16v2H10zM10 22h16v2H10z"></path>
            </svg>
        </div>
        <span class="mbl-title"
              [@navTitleTr]="isTitleAnimating ? 'scaleOut' : 'idle'"
              (@navTitleTr.done)="titleNavTransitionStepEnd($event)">{{mobileSectionTitle}}</span>
        <div class="logo-wrp" style="width: 32px; height: 32px">
            <svg version="1.1" style="transform: scale(0.8, 0.8); transform-origin: top left;">
                <use href="#ah-logo" width="40" height="40"/>
            </svg>
        </div>
        <div [@navItemsMblTr]="showMenuItemsInHandsetMode ? 'itemsIn' : 'itemsOut'" class="nav-items">
            <ul >
                <li *ngFor="let productPage of mainProductPages" [ngClass]="{'active': productPage.pageSelected}">
                    <a href="#{{productPage.path}}" (click)="onNavItemClick2($event, productPage)">{{productPage.navTitle}}</a>
                </li>
            </ul>
        </div>
        <div [@navOverlayMblTr]="showMenuItemsInHandsetMode ? 'fadeIn' : 'fadeOut'"
             class="nav-itms-mbl-overlay"
             (click)="onOverlayClick($event)">
        </div>
    </div>
    <div class="nav-shd-mbl"></div>
</nav>

