import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { ContentContainerComponent } from './content-container/content-container.component';
import { PageComponent } from './content-container/page-component/page.component';
import { AnsiHighlighterComponent } from './content-container/ansi-highlighter/ansi-highlighter.component';
import {LayoutModule} from '@angular/cdk/layout';
import {HttpClientModule} from '@angular/common/http';
import { ToolbarBtnComponent } from './utils/toolbar-btn/toolbar-btn.component';
import { PopupWrapperComponent } from './utils/popup-wrapper/popup-wrapper.component';
import { NtColorPickerComponent } from './utils/nt-color-picker/nt-color-picker.component';
import { NtInputComponent } from './utils/nt-input/nt-input.component';
import { NtEmailPromptComponent } from './utils/nt-email-prompt/nt-email-prompt.component';
import { AnsiHighlighterEulaComponent } from './content-container/ansi-highlighter/ansi-highlighter-eula/ansi-highlighter-eula.component';
import { AnsiHighlighterLicenseRegisterComponent } from './content-container/ansi-highlighter/ansi-highlighter-license-register/ansi-highlighter-license-register.component';
import { NtTabsComponent } from './utils/nt-tabs/nt-tabs.component';
import { NtTabComponent } from './utils/nt-tab/nt-tab.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        ContentContainerComponent,
        PageComponent,
        AnsiHighlighterComponent,
        ToolbarBtnComponent,
        PopupWrapperComponent,
        NtColorPickerComponent,
        NtInputComponent,
        NtEmailPromptComponent,
        AnsiHighlighterEulaComponent,
        AnsiHighlighterLicenseRegisterComponent,
        NtTabsComponent,
        NtTabComponent,
        FooterComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ColorPickerModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
