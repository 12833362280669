<section id="ansi-highlight-eula-sec" >
    <div class="{{responsiveCls}}" [ngClass]="{'nt-mbl': isHandset, 'nt-scr': !isHandset}">
        <div class="content">
            <h2>End User License Agreement for ANSI Highlighter Premium</h2>

            <p><span>"Plugin Vendor"</span> means the author of ANSI Highlighter Premium: Nifty Thoughts OÜ,
                Sepapaja tn 6, Harju maakond, 15551 Tallinn, Estonia.</p>

            <p><span>"JetBrains"</span> means JetBrains s.r.o. with its registered office at Na Hřebenech II 1718/10,
                Prague, 14000, Czech Republic, registered with the Commercial Register kept by the Municipal Court of Prague,
                Section C, file 86211, ID.Nr.: 265 02 275.</p>

            <p><span>"JetBrains Affiliate"</span> means the subsidiary and/or any associated companies of JetBrains.</p>

            <p><span>"JetBrains Marketplace"</span> means any platform operated by JetBrains or a JetBrains Affiliate
                on which JetBrains or a JetBrains Affiliate markets Plugins for JetBrains Products, including
                the website <a href="https://plugins.jetbrains.com">https://plugins.jetbrains.com</a> and/or any other
                website or other platform, whether named JetBrains Marketplace, JetBrains Plugins Repository, or otherwise.</p>

            <p><span>"JetBrains Products"</span> means any software program or service made available by JetBrains.</p>

            <p><span>"Plugin"</span> means the ANSI Highlighter Premium Plugin for all JetBrains Products that Developer
                makes available under this EULA. It enables the fast rendering of ANSI code sequences in log files
                regardless their size, and provides certain customization features.</p>

            <p><span>"Plugin Information"</span> means the following information and materials: (a) JetBrains Marketplace
                Plugin title, tags / category, name(s) of Plugin Vendor(s), product description, icon, logo or banner images,
                and any other information related to Plugins; (b) the metadata, graphics, artwork, images, trademarks,
                trade names, logos and other descriptive or identifying information and materials associated with Developer
                or appears in connection with Plugin; and (c) in the case of cloud hosted Plugins, an XML/JSON descriptor
                of Plugin.
            </p>

            <p><span>"Plugin Users"</span> means users that are able to access and use Plugin concurrently.</p>

            <p><span>"You"</span> means an individual or an entity concluding this EULA.</p>

            <p><span>"Site"</span> means the website available at the domain name address <a href="https://niftythoughts.com"></a>niftythoughts.com</p>

            <p>This EULA governs the terms of use of Plugin made available to You via JetBrains Marketplace. This EULA is
                entered into between You and Plugin Vendor.</p>

            <p>ANSI Highlighter Premium is a paid Plugin, you must ensure that the maximum number of Plugin Users does not
                exceed the number of Plugin Users for which you have purchased Plugin.</p>

            <p>By using ANSI Highlighter Premium, You hereby consent to the processing of your license metadata,
                including your license ID, email address, and name by Plugin Vendor for the sole purpose of verifying
                the license compliance with the terms of this agreement. Plugin Vendor hereby covenants that it shall
                not, at any time, collect, use or process any project data or other information from You, except as
                necessary for the aforementioned license validation purposes.</p>

            <p>You are authorized to use Plugin in accordance with the usage notes available at the plugin home page on the
                JetBrains Marketplace, for the period of time specified by Plugin Vendor in any country where JetBrains
                Products are available.</p>

            <p>You may not modify, reverse-engineer, decompile, or disassemble Plugin in whole or in part, or create
                any derivative works from Plugin, or sublicense any rights in Plugin, unless otherwise expressly
                authorized in writing by Plugin Vendor.</p>

            <p>Plugin is protected by copyright and other intellectual property laws and treaties. Plugin Vendor or its
                licensors own all title, copyright and other intellectual property rights in Plugin.</p>

            <p>In no event will Plugin Vendor be liable for any direct or indirect costs, losses, or damages associated with
                the use of Plugin Vendor’s plugins. Plugin Vendor shall not be liable to you for any lost profits or consequential
                damages, however caused, and in no event will Plugin Vendor’s aggregate liability arising out of or related
                to this agreement or the use of plugin exceed the fees which you paid via JetBrains plugin marketplace
                service for plugins in the three-month period preceding the claim. This limitation will apply even if
                Plugin Vendor has been advised of the possibility of the liability exceeding the amount and notwithstanding
                any failure of essential purpose of any limited remedy. Jetbrains' liability is excluded in its entirety
                as JetBrains is not a party to the contractual relationship between Plugin Vendor and customer.</p>
        </div>
    </div>
</section>
