import { Component, OnInit } from '@angular/core';
import {AbstractComponent} from '../abstract-component';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
    selector: 'nt-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss', '../navbar/navbar.component.scss']
})
export class FooterComponent extends AbstractComponent implements OnInit {

    year: number;

    constructor(public breakpointObserver: BreakpointObserver) {
        super(breakpointObserver);
    }

    ngOnInit(): void {
        this.year = new Date().getFullYear();
        super.ngOnInit();
    }

}
