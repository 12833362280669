<div class="pages-container" [ngClass]="{'perspective': perspective}">
    <nt-page *ngFor="let productPage of productPages"
             [productPage]="productPage"
             class="pt-page"
             [ngClass]="{'pg-slct': !productPage.hidden}"
             [@currentTab]="productPage.pageSelected ? 'open' : 'closed'"
             (@currentTab.start)="transitionStart(productPage)"
             (@currentTab.done)="transitionEnd(productPage)">
    </nt-page>
</div>
