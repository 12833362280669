import {Component, HostListener, Input, OnInit} from '@angular/core';
import {ProductPage} from '../product-page';
import {Subject} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ResponsiveConstants} from '../responsive-constants';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {AnimationEvent} from '@angular/animations';
import {AbstractComponent} from '../abstract-component';
import {Location} from '@angular/common';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'nt-navbar',
    animations: [
        trigger('navTitleTr', [
            state('scaleOut', style({
                transform: 'translate(-50%, -50%) scale(0)'
            })),
            state('idle', style({
                transform: 'translate(-50%, -50%) scale(1)'
            })),
            transition('idle => scaleOut', [
                animate('.2s')
            ]),
            transition('scaleOut => idle', [
                animate('0.2s')
            ]),
        ]),
        trigger('navItemsMblTr', [
            state('itemsOut', style({
                display: 'none'
            })),
            state('itemsIn', style({
                display: 'block'
            })),
            transition('itemsOut => itemsIn', [
                animate('0.3s ease-out', keyframes([
                    style({display: 'block', transform: 'translateX(-100%)', offset: 0.0}),
                    style({transform: 'translateX(0)', offset: 1.0})
                ]))
            ]),
            transition('itemsIn => itemsOut', [
                animate('0.3s ease-out', keyframes([
                    style({transform: 'none', offset: 0.0}),
                    style({transform: 'translateX(-100%)', offset: 1.0})
                ]))
            ]),
        ]),
        trigger('navOverlayMblTr', [
            state('fadeIn', style({
                opacity: 0.4,
                display: 'block'
            })),
            state('fadeOut', style({
                opacity: 0,
                display: 'none'
            })),
            transition('fadeOut => fadeIn', [
                animate('0.3s', keyframes([
                    style({display: 'block', opacity: 0, offset: 0.0}),
                    style({display: 'block', opacity: 0.4, offset: 1.0})
                ]))
            ]),
            transition('fadeIn => fadeOut', [
                animate('0.3s')
            ]),
        ])
    ],
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends AbstractComponent implements OnInit {
    @Input() animationEndEventSubject: Subject<ProductPage>;
    @Input() productPages: ProductPage[];
    mainProductPages: ProductPage[];
    private isAnimatingPageFLip = false;
    isTitleAnimating = false;
    mobileSectionTitle: string;
    showMenuItemsInHandsetMode = false;
    constructor(public breakpointObserver: BreakpointObserver,
                private location: Location,
                private titleService: Title) {
        super(breakpointObserver);
    }

    ngOnInit() {
        super.ngOnInit();
        this.mainProductPages = this.productPages.filter(page => page.showInNavbar);
        const self = this;
        this.mobileSectionTitle = this.getSelectedNavItem().mobileSectionTitle;
        this.breakpointObserver
            .observe(ResponsiveConstants.HANDSET_MEDIA_QUERY)
            .subscribe((_st) => {
                self.showMenuItemsInHandsetMode = false;
            });
        setTimeout(() => {
            self.pathChange(null);
            self.getPathPage().hidden = false;
            self.animationEndEventSubject.subscribe((_v) => {
                self.isAnimatingPageFLip = false;
            });
        });
        this.location.onUrlChange((_url, _state) => {
            this.pathChange(null);
        });

    }

    @HostListener('window:popstate', ['$event'])
    pathChange(event) {
        if(event) {
            event.preventDefault();
        }
        const productPage = this.getPathPage();
        this.titleService.setTitle(productPage.browserTitle);
        const self = this;
        const previousSelectedPage = self.getSelectedNavItem();
        if (previousSelectedPage && previousSelectedPage === productPage) {
            return;
        }

        if (previousSelectedPage) {
            previousSelectedPage.pageSelected = false;
        }
        productPage.pageSelected = true;
        self.isAnimatingPageFLip = true;
    }

    titleNavTransitionStepEnd(evt: AnimationEvent) {
        if (evt.toState === 'scaleOut') {
            this.mobileSectionTitle = this.getSelectedNavItem().mobileSectionTitle;
            this.isTitleAnimating = false;
        }
    }

    onNavItemClick(e: MouseEvent, productPage: ProductPage) {
        this.onNavItemClick2(e, productPage);
        this.mobileSectionTitle = productPage.mobileSectionTitle;
    }
    onNavItemClick2(e: MouseEvent, productPage: ProductPage) {
        if (e) {
            e.preventDefault();
        }
        if (this.isAnimatingPageFLip) {
            return false;
        }
        if (this.isHandset) {
            // allowing nav title animation (scale in/out) even if page didn't change on purpose... just for fun
            this.isTitleAnimating = true;
        }
        if (productPage.path === this.location.path(false)) {
            return;
        }
        this.location.go(productPage.path);
    }
    onResponsiveBtnClick() {
        this.showMenuItemsInHandsetMode = !this.showMenuItemsInHandsetMode;
    }

    onOverlayClick(e: MouseEvent) {
        this.onResponsiveBtnClick();
        e.preventDefault();
        e.stopPropagation();
    }

    getSelectedNavItem(): ProductPage {
        for (const productPage of this.productPages) {
            if (productPage.pageSelected) {
                return productPage;
            }
        }
        return this.getPathPage();
    }
    private getPathPage(): ProductPage {
        let path = this.location.path(false);
        if (!path) {
            this.selectPage(this.productPages[0]);
            return this.productPages[0];
        }
        for (const page of this.productPages) {
            if (page.matchesPath(path)) {
                this.selectPage(page);
                return page;
            }
        }
        this.selectPage(this.productPages[0]);
        // none is matched => default to root
        this.location.go('');
        return this.productPages[0];
    }
    private selectPage(pg: ProductPage) {
        for (const page of this.productPages) {
            if(pg === page) {
                if (!pg.pageSelected) {
                    pg.pageSelected = true;
                }
            } else if(page.pageSelected) {
                page.pageSelected = false;
            }
        }
    }

}
