import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {ProductPage} from '../../product-page';
import {AnsiHighlighterComponent} from '../ansi-highlighter/ansi-highlighter.component';
import {AnsiHighlighterEulaComponent} from '../ansi-highlighter/ansi-highlighter-eula/ansi-highlighter-eula.component';
import {AnsiHighlighterLicenseRegisterComponent} from '../ansi-highlighter/ansi-highlighter-license-register/ansi-highlighter-license-register.component';
import {AbstractComponent} from '../../abstract-component';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
  selector: 'nt-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent extends AbstractComponent implements OnInit, AfterViewInit {

    @Input() productPage: ProductPage;
    constructor(public breakpointObserver: BreakpointObserver,
                private elRef: ElementRef) {
        super(breakpointObserver);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        this.productPage.pageElement = this.elRef.nativeElement;
    }

    isANSIHighlighterPage() {
        return AnsiHighlighterComponent.PRODUCT.path === this.productPage.path;
    }

    isANSIHighlighterEula() {
        return AnsiHighlighterEulaComponent.PRODUCT.path === this.productPage.path;
    }

    isANSIHighlighterLicenseRegister() {
        return AnsiHighlighterLicenseRegisterComponent.PRODUCT.path === this.productPage.path;
    }

}
